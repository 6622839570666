$blue-sky: #89C6FF;
$blue-light: #2D34CE;
$blue-primary: #1B0088;
$blue-dark: #120267;
$sky: #CDCFFF;

$white: #ffffff;

$gray-light: #EDEDED;
$grey-medium: #F5F5F5; 
$gray-med: #BFBFBF;
$gray-dark: #666666;
$gray-light-med: #E5E5E5;
$gray-text: #8C8C8C;

$coral: #ED1650;
$coral-light: #FF3F6D;

$green-light-hover: #D4F1F0;
$green-light: #00AFA9;
$green-med: #2DCEB1;
$green-light-dark: #006661;

$latam-light: latam-light, Arial, Helvetica, sans-serif;
$latam-bold: latam-bold, Arial, Helvetica, sans-serif;

$green: #05AF38;
$yellow: #E4CD06;
$red: #ED1650;

$grey-medium: #F5F5F5;
