/* You can add global styles to this file, and also import other style files */
@charset "UTF-8";
@import 'variables';
@import 'material';

::-webkit-scrollbar {
	-webkit-appearance: none;
}

::-webkit-scrollbar:vertical {
	width: 12px;
}

::-webkit-scrollbar-button:increment,
::-webkit-scrollbar-button {
	display: none;
}

::-webkit-scrollbar:horizontal {
	height: 12px;
}

::-webkit-scrollbar-thumb {
	background-color: #cfd5ed;
	border-radius: 20px;
	border: 2px solid #cfd5ed;
}

::-webkit-scrollbar-track {
	border-radius: 10px;
}

* {
	outline: none;
	&:focus {
		outline: none !important;
	}
}

app-login {
	background: $blue-primary;
	display: flex;
	width: 100%;
	height: 100vh;
	overflow: hidden;
	justify-content: center;
	align-items: center;
	text-align: center;
}
.icon-class {
	border-color: #00afa9 !important;
	color: #00afa9 !important;
}

button {
	&.primary,
	&.secondary {
		min-width: 120px;
		border-radius: 0;
		line-height: 40px;
		margin: 8px 0;
		font-size: 13px;
		margin-left: 15px;
		font-family: $latam-bold;
		padding: 0 1.5em;
		transition: background 0.4s cubic-bezier(0.25, 0.8, 0.25, 1), box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
		@media screen and (max-width: 420px) {
			width: 100%;
		}
	}
}

/* ============================================================================== */
/* =============================== LATAM WEBFONTS =============================== */
/* ============================================================================== */
@font-face {
	font-family: 'LATAM Sans Regular';
	src: url(/assets/fonts/_Latam_sans/LatamSansST-Regular.eot) format('eot'),
		url(/assets/fonts/_Latam_sans/LatamSansST-Regular.ttf) format('ttf'),
		url(/assets/fonts/_Latam_sans/LatamSansST-Regular.woff) format('woff'),
		url(/assets/fonts/_Latam_sans/LatamSansST-Regular.woff2) format('woff2');
}

@font-face {
	font-family: 'LATAM Sans Light';
	src: url(/assets/fonts/_Latam_sans_light/Latam_Sans_Light.woff) format('woff'),
		url(/assets/fonts/_Latam_sans_light/Latam_Sans_Light.otf) format('otf');
}

@font-face {
	font-family: 'LATAM Sans Bold';
	src: url(/assets/fonts/_Latam_sans_bold/LatamSansST-Bold.eot) format('eot'),
		url(/assets/fonts/_Latam_sans_bold/LatamSansST-Bold.ttf) format('ttf'),
		url(/assets/fonts/_Latam_sans_bold/LatamSansST-Bold.woff) format('woff'),
		url(/assets/fonts/_Latam_sans_bold/LatamSansST-Bold.woff2) format('woff2');
}
/* ***************************** END LATAM WEBFONTS ***************************** */

/* ============================================================================ */
/* ===================== Side menu buttons (only desktop) ===================== */
/* ============================================================================ */
.btn-menu {
	width: 50px;
	height: 50px;
	border: 1px solid transparent;
	background-color: white;
	color: #acacac;
	font-family: 'LATAM Sans Bold';
	transition: 0.3s;
	outline: none;
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;

	&:hover,
	&:focus {
		background-color: #f7b6cc;
	}

	&:focus {
		border: 2px solid #1b0088;
	}

	&.active,
	&:active {
		color: #fff;
		background-color: #ed1650;
	}

	&:disabled {
		color: #acacac;
		background-color: #eeeeee;
		cursor: not-allowed;
	}
}
/* ******************* Side menu buttons end (only desktop) ******************* */
/* ============================================================================== */
/* =========================== Level 1 button - LATAM =========================== */
/* ============================================================================== */
.btn_level_1 {
	border: 1px solid transparent;
	border-radius: 5px;
	background-color: #ed1650;
	color: #fff;
	font-family: 'LATAM Sans Regular';
	-webkit-transition: 0.3s;
	transition: 0.3s;
	outline: none;
	cursor: pointer;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;

	&:hover,
	&:focus {
		background-color: #f53859;
		color: #fff;
		-webkit-box-shadow: 0px 5px 7px 0px rgba(184, 184, 184, 1);
		box-shadow: 0px 5px 7px 0px rgba(184, 184, 184, 1);
	}

	&:focus {
		border: 2px solid #1b0088;
	}

	&:disabled {
		color: #858585;
		background-color: #e1e1e1;
		cursor: not-allowed;
	}
}
/* ************************* Level 1 button end - LATAM ************************* */

/* =========================== Level 3 button - LATAM =========================== */
/* ============================================================================== */
.btn_level_3 {
	border: 2px solid #1b0088;
	border-radius: 5px;
	background-color: #fff;
	color: #1b0088;
	-webkit-transition: 0.3s;
	transition: 0.3s;
	outline: none;
	cursor: pointer;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;

	&:hover,
	&:focus {
		background-color: #2d34ce;
		color: #fff;
		-webkit-box-shadow: 0px 5px 7px 0px rgba(184, 184, 184, 1);
		box-shadow: 0px 5px 7px 0px rgba(184, 184, 184, 1);
	}

	&:disabled {
		color: #858585;
		background-color: #e1e1e1;
		cursor: not-allowed;
	}
}
/* ************************* Level 3 button end - LATAM ************************* */

/* ============================================================================== */
/* ================================= LATAM ICONS ================================ */
/* ============================================================================== */
@font-face {
	font-family: 'LATAM Interactions Icons';
	src: url('/assets/icons/latam-icons-interactions.ttf') format('truetype');
}

.latam-icon {
	position: relative;
	top: 1px;
	display: inline-block;
	font-family: 'LATAM Interactions Icons';
	font-style: normal;
	font-weight: normal;
	line-height: 1;
	font-size: 24px;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon-home:before {
	content: '\E900';
}
.icon-calendar:before {
	content: '\E921';
}

html,
body {
	height: 100%;
}

body {
	margin: 0;
	background-color: $white !important;
	font-family: Roboto, 'Helvetica Neue', sans-serif;
	margin: 0;
	padding: 0;
	background-color: #f5f6fb !important;
	overflow-x: auto !important;
	overflow-y: hidden;
}

.mat-mdc-select-panel {
	// margin-top: 45px;
}

.snackCorrect {
	--mdc-snackbar-container-color: #2dce5a;
	--mdc-snackbar-supporting-text-size: 18px;
	min-width: 801px !important;
	min-height: 72px !important;
}

.snackIncorrect {
	--mdc-snackbar-container-color: #ea3a13;
	min-width: 801px !important;
	min-height: 72px !important;
	white-space: pre-line; /* Ensures new lines in the message */
}

.mat-mdc-snack-bar-container {
	text-align: center;
	color: white !important;
	background-color: #fff !important;
	padding: 0 0 !important;
}

.snackValidation {
	background-color: #ffffff;
	min-width: 801px !important;
	min-height: 72px !important;
	.mdc-snackbar__label {
		padding: 0 0;
	}
}

.mat-mdc-snack-bar-container .mat-mdc-snackbar-surface {
	min-width: unset !important;
	max-width: unset !important;
	width: 100% !important;
	padding: 0 !important;
	margin: 24px !important;
}

.mat-mdc-snack-bar-container {
	background-color: transparent !important;
}

.mat-mdc-simple-snack-bar {
	display: inline-block !important;
	font-size: 30px !important;
	vertical-align: middle;
	margin: auto;
	padding-top: 10px;
	padding-bottom: 10px;
}

.wtBorder {
	background-color: #8b8ffd !important;
}

html {
	.loader {
		background: transparent url(./assets/images/loading.gif) no-repeat center center;
		animation: none;
		transition: none;
		width: 400px;
		height: 400px;
		border: none;
	}
}

.cdk-overlay-container {
	z-index: 99999999999999;
}

.cell-grad-common {
	background: linear-gradient(to bottom right, var(--main-td-color), white) !important;
}

//UPDATE 12 2024
.mdc-button, .mdc-icon-button, .mdc-stroked-button {
	color: inherit !important;
	background: transparent;
}

.mdc-button .mdc-button__label {
	//color: white !important;
}

.mat-mdc-card-content {
	padding: 0px !important;
	font-size: 14px;
}

.mat-mdc-card-header {
	padding: 5px 0px !important;
}

.mat-mdc-button-toggle, .mdc-card, .handsontable {
	font-family: Roboto, Helvetica Neue, sans-serif !important;
}

.multicalendar > app-calendar-rem:nth-child(2) {
	margin-left: 10px;
}

.indicadores-seasons .mat-mdc-form-field-infix, .indicadores-seasons .mdc-text-field {
	padding: 0px !important;
}

.mat-mdc-dialog-surface{
	padding: 24px;
}

// FINgit  UPDATE 12 2024

// .handsontable.listbox td {
//   background: #F4F6FC;
//   text-align: center;
//   height: auto !important;
//   &:hover {
//     background: #CDCFFF !important;
//   }

//   &.listbox.current.highlight {
//     background: #CDCFFF !important;
//     text-align: center;

//   }
// }

// .headersLinks .handsontable thead th:nth-child(1) {
//   background:#F4D9D9;

// }

// .headersLinks .handsontable  thead th:nth-child(2) {
//   background: #F4D9D9

// }
// .headersLinks .handsontable thead th:nth-child(3) {
//   background:#F4D9D9

// }
// .headersLinks .handsontable thead th:nth-child(4) {
//   background:#F4D9D9

// }

// .htCore th div.relative {
//   padding: 0 !important;
//   span.colHeader {
//     width: 100%;
//     height: 32px;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     .bgHeadersKeyTrue {
//       background: #F4D9D9;
//       width: 100%;
//       height: 42px;
//       display: flex;
//       align-items: center;
//       justify-content: center;
//     }
//   }
// }

// .handsontableInput {
//   box-shadow: none !important;
// }

// Mat Checkbox
.mat-mdc-list-option,
.mat-mdc-checkbox {
	&.mat-accent {
		--mdc-checkbox-selected-focus-icon-color: #3f51b5 !important;
		--mdc-checkbox-selected-hover-icon-color: #3f51b5 !important;
		--mdc-checkbox-selected-icon-color: #3f51b5 !important;
		--mdc-checkbox-selected-pressed-icon-color: #3f51b5 !important;
	}
}

.select-rem {
	/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
	.mdc-text-field--outlined .mdc-notched-outline{
		top: 0;
		margin-top: 2px;
	}
	/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
	.mdc-text-field--outlined .mdc-notched-outline__leading {
		border-radius: 2px 0 0 2px;
		background-color: #e8114b !important;
		border-color: 0 !important;
		border-width: 0px !important;
	}
	/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
	.mdc-text-field--outlined .mdc-notched-outline__notch {
		background-color: #e8114b !important;
		border-color: 0 !important;
		border-width: 0px !important;
	}
	/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
	.mdc-text-field--outlined .mdc-notched-outline__trailing {
		border-radius: 0 2px 2px 0;
		background-color: #e8114b !important;
		border-color: 0 !important;
		border-width: 0px !important;
	}
	/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
	.mat-form-field-appearance-outline .mat-form-field-flex {
		margin-top: 0px;
	}
	/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
	.mat-form-field-appearance-outline:not(.mat-form-field-disabled) .mat-form-field-flex:hover .mat-form-field-outline {
		opacity: 1 !important;
	}
	/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
	.mat-form-field-appearance-outline .mat-form-field-wrapper {
		margin: 0;
	}
	/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
	.mat-form-field-wrapper {
		padding-bottom: 0;
	}
	/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
	.mat-form-field-wrapper {
		padding-bottom: 0;
	}
	/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
	.mat-form-field-appearance-outline .mat-form-field-outline-thick {
		opacity: 1;
		border-radius: 2px;
		border-right: 2px solid #a80c36;
		border-bottom: 2px solid #a80c36;
	}
	/* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
	.mat-mdc-select .mdc-floating-label {
		color: #ffffff;
	}
	/* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
	.mat-mdc-select .mdc-select__selected-text {
		color: #ffffff;
		position: relative;
		top: -5px;
	}
	/* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
	.mat-select-arrow {
		color: #ffffff;
	}

	.mat-mdc-select .mat-mdc-select-value {
		color: #ffffff;
	}

	.mat-mdc-select .mat-mdc-select-arrow-wrapper {
		color: #ffffff;
	}

	/* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
	.mat-mdc-form-field.mat-focused.mat-primary .mat-select-arrow {
		color: #ffffff;
	}
	/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
	.mat-form-field-flex > .mat-form-field-infix {
		padding: 5px 0 !important;
		border-top-width: 15px;
	}
	/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
	.mat-form-field-label-wrapper {
		top: -1.5em;
	}

	.mdc-text-field--outlined .mdc-notched-outline {
		z-index: unset !important;
	}

	.mat-mdc-form-field {
		height: 40px;
		justify-content: center;
	}

	.mat-mdc-text-field-wrapper {
		height: 100%;
	}

	.mat-mdc-form-field-infix {
		padding-top: 10px !important;
	}

	.mat-mdc-select-placeholder {
		color: #fff;
	}
}

.select-white {
	.mat-mdc-select-value-text {
		color: #1b0088 !important;
	}

	.mat-mdc-select-arrow-wrapper {
		color: #1b0088 !important;
	}
}
